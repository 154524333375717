import { ReactComponent as Mug } from "../assets/svg/mug-hot-alt.svg";
import { ReactComponent as File } from "../assets/svg/icon-booking.svg";
import { ReactComponent as Check } from "../assets/svg/icon-check.svg";
import { ReactComponent as Exit } from "../assets/svg/icon-door.svg";
import { ReactComponent as windows } from "../assets/svg/icon-windows.svg";
import { ReactComponent as Ocupado } from "../assets/svg/icon-ocupado.svg";
import { ReactComponent as VagoSujo } from "../assets/svg/icon-vago-sujo.svg";
import { ReactComponent as VagoLimpo } from "../assets/svg/icon-vago-limpo.svg";
import { ReactComponent as Indisponivel } from "../assets/svg/icon-indisponivel.svg";
import { ReactComponent as Maintenance } from "../assets/svg/icon-maintenance.svg";
import { ReactComponent as addUser } from "../assets/svg/icon-gestor.svg";
import { ReactComponent as editPencil } from "../assets/svg/icon-pencil.svg";
import { ReactComponent as VisitorIcon } from "../assets/svg/icon-visitante-grayscale.svg";

const SVG = {
  Mug,
  File,
  Check,
  Exit,
  windows,
  Ocupado,
  VagoSujo,
  VagoLimpo,
  Indisponivel,
  Maintenance,
  addUser,
  editPencil,
  VisitorIcon
};

export default SVG;
