import React, { useState } from "react";
import { CustomText, Input, InputTags } from "../../../components/index";
import { Grid } from "@material-ui/core";
import { masks, validations } from "../../../utils";
import { fonts } from "../../../config";
import Styles from "../styles/Styles";
import { FormFull } from "form-full";
import { customModal } from "../../../components/modals/utils";

function ModalRegistry({ data, onSubmit }: { data?: any; onSubmit?: any }) {
  function handleSubmit(data) {
    console.log(data);
  }

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          minWidth: "clamp(300px, 20vw, 650px)",
          paddingInline: "25px",
        }}
      >
        <FormFull onSubmit={handleSubmit}>
          <CustomText
            style={{ marginBottom: "20px" }}
            fontFamily={fonts.bold}
            fontSize={14 / 8}
            textColor="#B2B2B2"
          >
            DADOS BÁSICOS DA PRESTADORA DE SERVIÇO:
          </CustomText>
          <Grid alignItems="flex-end" container spacing={2}>
            <Grid item xs={12} md={12} lg={6}>
              <Input
                disableError
                name="companyName"
                label="None da Empresa"
                defaultValue={data?.companyName || ""}
                validation={validations.isValidFullname}
                required={"*Campo Obrigatório"}
                white
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Input
                disableError
                name="cnpj"
                label="CNPJ"
                defaultValue={data?.cnpj || ""}
                mask={masks.CNPJ}
                validation={validations.validateCNPJ}
                maskToSubmit={masks.removeNumberMask}
                required={"*Campo Obrigatório"}
                white
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <Input
                disableError
                name="responsibleName"
                label="NOME RESPONSÁVEL"
                defaultValue={data?.responsibleName || ""}
                validation={validations.isValidFullname}
                required={"*Campo Obrigatório"}
                white
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <Input
                disableError
                required={"*Campo Obrigatório"}
                mask={masks.inputMaskTELWithDDD}
                name="phone"
                label="Telefone"
                white
                maskToSubmit={masks.removeNumberMask}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={12}>
              <Input
                disableError
                name="email"
                label="E-mail representante"
                defaultValue={data?.emailCompany || ""}
                validation={validations.isEmailValid}
                required={"*Campo Obrigatório"}
                white
              />
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "12px",
              marginTop: "40px",
              width: "100%",
            }}
          >
            <Styles.CancelButton
              fullWidth={false}
              onClick={() => {
                customModal.close();
              }}
            >
              Cancelar
            </Styles.CancelButton>
            <Styles.RegitryButton fullWidth={false} action="submit">
              Sim, Confirmar {data ? "Edição" : "Cadastro"}
            </Styles.RegitryButton>
          </div>
        </FormFull>
      </div>
    </>
  );
}

export default ModalRegistry;
