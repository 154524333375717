import React from "react";
import Styles from "../styles";
import { FormFull } from "form-full";
import { masks, validations } from "../../../utils";

import { fonts, SVG } from "../../../config";
import { Grid } from "@material-ui/core";
import { customModal } from "../../../components/modals/utils";
import ptBr from "../../../config/texts/pt-br";
import { CustomText, Input } from "../../../components/index";

function ModalVisitorAccess({ item }) {
  const texts = ptBr.login;
  const onSubmit = async (data: any) => {
    customModal.close();
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          minWidth: "clamp(250px, 26vw, 500px)",
          paddingInline: "25px",
          marginBottom: "20px",
        }}
      >
        <FormFull onSubmit={onSubmit}>
          <CustomText
            style={{ marginBottom: "20px" }}
            fontFamily={fonts.bold}
            fontSize={14 / 8}
            textColor="#B2B2B2"
          >
            DADOS BÁSICOS DO VISITANTE
          </CustomText>
          <Grid alignItems="flex-end" container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
              <Input
                required={"*Campo Obrigatório"}
                name="fullName"
                defaultValue={item?.name || ""}
                validation={validations.isValidFullname}
                label="Nome Completo"
                white
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Input
                required={"*Campo Obrigatório"}
                name="cpf"
                defaultValue={item?.cpf || ""}
                validation={validations.validateCPF}
                maskToSubmit={masks.removeNumberMask}
                label="CPF"
                white
              />
            </Grid>
            <Grid item xs={12} md={6} lg={12}>
              <Input
                required={"*Campo Obrigatório"}
                name="email"
                defaultValue={item?.email || ""}
                validation={validations.isEmailValid}
                label="EMAIL"
                white
              />
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "12px",
              marginTop: "25px",
              width: "100%",
            }}
          >
            <Styles.CancelButton
              fullWidth={false}
              onClick={() => customModal.close()}
            >
              Cancelar
            </Styles.CancelButton>
            <Styles.ConfirmButton fullWidth={false} action="submit">
              Confirmar
            </Styles.ConfirmButton>
          </div>
        </FormFull>
      </div>
    </>
  );
}

export default ModalVisitorAccess;
